import React from "react";
import { Link } from "gatsby";
import { graphql } from "gatsby";

import Layout from "../layout";
import { MDXRenderer } from "gatsby-plugin-mdx";

export const pageQuery = graphql`
  {
    notes: allMdx(
      filter: {
        frontmatter: {
          draft: {ne: true},
          category: {eq: "Notes"}
        }
      }, 
      sort: {
        fields: [frontmatter___date, frontmatter___title], order: DESC
      }
    ) {
      ...NoteFragment
    }
  }

  fragment NoteFragment on MdxConnection {
    edges {
      node {
        body
        fields {
          slug
        }
        frontmatter {
          title
          tags
          category
          date
          niceDate: date(formatString: "DD MMM YYYY")
        }
      }
    }
  }

`;


export default ({ data }) => {
  const notes = data.notes.edges.map(e => e.node);
  return (
    <Layout>
      <section className="Notes">
        <header className="Notes-meta">
          <h1 className="Notes-title">Notes</h1>
        </header>
        <section className="Notes-list">

          {notes.map((note, index) => (
            <article className="Note" key={index}>
              <header className="Note-header">
                <h2 className="Note-title">
                  <Link to={note.fields.slug}>{note.frontmatter.title}</Link>
                </h2>
              </header>
              <section className="Note-meta">

              </section>
              <section className="Note-preview">
                <MDXRenderer>{note.body}</MDXRenderer>
              </section>
            </article>
          ))}
        </section>
      </section>
    </Layout>
  );
}


